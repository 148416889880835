'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useMasterDataIsReady } from 'master-data/hooks/useMasterDataIsReady/useMasterDataIsReady'
import { reportCustomErrorUtil } from 'monitoring/reportCustomErrorUtil/reportCustomErrorUtil'
import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useSession } from 'session/src/hooks/useSession'

import { parseMasterDataWindow } from '../utils/parseMasterDataWindow'

export interface CustomError {
	key: string
	message: string
	hint?: string
	parentingInfo?: number | boolean
}

interface DynatraceType {
	loaded: boolean
	reportCustomError: (customError: CustomError) => void
}

interface DynatraceProviderProps {
	children: React.ReactNode
}

const jsSrc = process.env.NEXT_PUBLIC_DYNATRACE_JS

const DynatraceProviderContext = createContext<DynatraceType>({
	loaded: false,
	reportCustomError: () => {},
})

export function DynatraceProvider({
	children,
}: DynatraceProviderProps): React.ReactNode {
	const { cookiesConsent } = useCookiesContext()
	const [loaded, setLoaded] = useState(false)
	const masterData = useMasterData()

	const { pageType } = masterData
	const { masterDataIsReady } = useMasterDataIsReady()
	const { isReady, logged } = useSession()

	const customErrorsStack = useRef<CustomError[]>([])

	const reportCustomError = (error: CustomError) => {
		if (loaded) {
			reportCustomErrorUtil(error)
		}
		customErrorsStack.current.push(error)
	}

	useEffect(() => {
		if (masterDataIsReady) {
			window.masterData = parseMasterDataWindow(masterData)
		}
	}, [masterDataIsReady, masterData])

	useEffect(() => {
		if (isReady) {
			window.session = { logged }
		}
	}, [isReady, logged])

	useEffect(() => {
		if (jsSrc && typeof cookiesConsent === 'boolean' && !loaded) {
			const script = document.createElement('script')
			script.src = jsSrc
			script.async = true
			script.crossOrigin = 'anonymous'
			script.onload = () => {
				setLoaded(true)
			}

			document.body.appendChild(script)
		}
	}, [cookiesConsent])

	useEffect(() => {
		if (jsSrc && typeof cookiesConsent === 'boolean' && loaded) {
			if (cookiesConsent) {
				window.dtrum.enable()
				window.dtrum.enableManualPageDetection()
				window.dtrum.setPage({
					name: window.location.pathname + window.location.search,
					group: pageType,
				})
				customErrorsStack.current.forEach((error) =>
					reportCustomErrorUtil(error)
				)
				customErrorsStack.current = []
			} else {
				window.dtrum.disable()
			}
		}
	}, [cookiesConsent, loaded])

	const contextValues: DynatraceType = useMemo(
		() => ({
			loaded,
			reportCustomError,
		}),
		[loaded]
	)

	return (
		<DynatraceProviderContext.Provider value={contextValues}>
			{children}
		</DynatraceProviderContext.Provider>
	)
}

export function useDynatrace(): DynatraceType {
	const context = useContext(DynatraceProviderContext)
	if (context === undefined) {
		throw new Error('Dynatrace must be used within a DynatraceProvider')
	}
	return context
}
