import type { MasterData } from 'types/masterData'

export const parseMasterDataWindow = (masterData: MasterData) => {
	const { country, brand, pageType } = masterData

	const { countryISO, languageISO, hasOnlineSale } = country

	return {
		brand,
		countryISO,
		hasOnlineSale,
		languageISO,
		pageType,
	}
}
